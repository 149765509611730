import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  Control,
  RegisterOptions,
  FieldValues,
  Controller,
} from "react-hook-form";
import { MenuData } from "./CustomSelect";
import LabelInput from "./LabelInput";
import { useEffect, useState } from "react";
import { CustomFormHelperText } from "./CustomFormHelperText";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  control: Control<any, any>;
  rules:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  label: string;
  info: string;
  placeholder: string;
  listMenuItem: MenuData[];
  required?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  showLabelInput?: boolean | undefined;
}

export const MultipleAutoComplete = (props: Props) => {
  const { t } = useTranslation(["common"], {
    nsMode: "fallback",
  });

  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    let temp = [t("All")];

    temp = [...temp, ...props.listMenuItem.map((item) => item.nameEN)];

    setOptions(temp);
  }, [props.listMenuItem]);

  return (
    <>
      <FormControl fullWidth>
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={props.defaultValue ?? []}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <>
                {props.showLabelInput === undefined ||
                props.showLabelInput === true ? (
                  <LabelInput
                    label={props.label}
                    info={props.info}
                    required={props.required}
                  />
                ) : undefined}

                <Autocomplete
                  size="small"
                  multiple
                  limitTags={2}
                  options={options}
                  value={value}
                  defaultValue={props.defaultValue ?? []}
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={
                          option === t("All") &&
                          value.length === options.length - 1
                            ? true
                            : selected
                        }
                      />
                      {option}
                    </li>
                  )}
                  sx={{
                    width: "100%",
                    backgroundColor: "var(--white)",
                    margin:
                      props.showLabelInput === undefined ||
                      props.showLabelInput === true
                        ? undefined
                        : 0,
                    "& .MuiAutocomplete-tag": {
                      maxWidth: "40%",
                      fontSize: "12px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        props.showLabelInput === undefined ||
                        props.showLabelInput === true
                          ? undefined
                          : props.label
                      }
                      sx={{
                        zIndex: 0,
                        "& .MuiOutlinedInput-root": {
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                        "& .MuiInputBase-sizeSmall": {
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        },
                      }}
                      error={error ? true : false}
                    />
                  )}
                  onChange={(event, value: string[], reson) => {
                    let valueTemp: string[] = value;

                    if (
                      reson === "selectOption" &&
                      valueTemp.includes(t("All")) &&
                      options.length !== value.length
                    ) {
                      valueTemp = props.listMenuItem.map((item) => item.nameEN);
                    } else if (
                      reson === "selectOption" &&
                      valueTemp.includes(t("All")) &&
                      options.length === value.length
                    ) {
                      valueTemp = [];
                    }

                    onChange(valueTemp);
                  }}
                />
                <CustomFormHelperText error={error} />
              </>
            );
          }}
        />
      </FormControl>
    </>
  );
};
