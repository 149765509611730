import React from 'react';
import { 
  FormControl, 
  Select, 
  MenuItem, 
  SelectChangeEvent 
} from '@mui/material';
import { Control, Controller, UseFormGetValues } from 'react-hook-form';
import LabelInput from '../../../components/input/LabelInput';

interface FormValues {
  isRequireBaseYear: boolean;
  nameTh: string;
  nameEn: string;
  unit: string;
  attachment: string;
  components: any[];
}

interface BaseYearSelectProps {
  control: Control<FormValues>;
  t: (key: string) => string;
  getValues: UseFormGetValues<FormValues>;
}

const BaseYearSelect: React.FC<BaseYearSelectProps> = ({ control, t, getValues }) => {
  return (
    <Controller
      name="isRequireBaseYear"
      control={control}
      defaultValue={getValues("isRequireBaseYear") === true ? true : false}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <LabelInput
            label={t("Base-Year")}
            info={t("Base-Year-Period-description")}
            required={true}
          />
          <Select
            labelId="base-year-label"
            value={value ? "yes" : "no"}
            onChange={(e: SelectChangeEvent) => {
              onChange(e.target.value === "yes");
            }}
            sx={{
              margin: "8px 0",
              height: "40px",
            }}
          >
            <MenuItem value="yes">{t("Required")}</MenuItem>
            <MenuItem value="no">{t("Not-Required")}</MenuItem>
          </Select>
        </FormControl>
      )}
    />
  );
};

export default BaseYearSelect;