import { Box } from "@mui/material";
import Layout from "../../Layout";
import { TabMenu } from "../../components/tabMenu/TabMenu";
import { tabMenuEmissionData } from "../emission";
import { EcoEfficiencyDataGrid } from "./EcoEfficiencyDataGrid";
import { EcoEfficiencyProvider } from "../../contexts/EcoEfficiencyContext";
import { OrganizationProvider } from "../../contexts/OrganizationContext";

export const EcoEfficiency = () => {
  return (
    <EcoEfficiencyProvider>
      <OrganizationProvider>
        <Layout>
          <Box
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            height={"100%"}
            color={"var(--dark)"}
          >
            <TabMenu tabMenuData={tabMenuEmissionData} />

            <Box
              p={"40px 40px"}
              display={"flex"}
              flexDirection="column"
              gap={"40px"}
              alignItems={"start"}
              flex={1}
              bgcolor={"var(--white)"}
            >
              <Box display={"flex"} flex={1} width={"100%"}>
                <EcoEfficiencyDataGrid />
              </Box>
            </Box>
          </Box>
        </Layout>
      </OrganizationProvider>
    </EcoEfficiencyProvider>
  );
};
