import { Avatar, Box, Chip, Typography } from "@mui/material";
import { AccessControlContext } from "../../../contexts/AccessControlContext";
import { useAuth } from "../../../contexts/UserContext";
import { Trans, useTranslation } from "react-i18next";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";
import {
  Member,
  MemberUser,
} from "../../../models/dto/response/ResponseMembers";
import { tableStyle } from "../../emission/data-table/EmissionDataTableStyle";
import { USER_STATUS } from "../../../constants/form";
import React, { useEffect, useState } from "react";
import { stringAvatar } from "../../../utils/avatar";
import { PaginationForAccessControl } from "./PaginationForAccessControl";
import UsersService from "../../../service/usersService";
import alertService from "../../../components/alert/alertService";

export const UsersTable = () => {
  const { t } = useTranslation(["common", "input", "account"], {
    nsMode: "fallback",
  });

  const {
    usersRows,
    statusValue,
    roleValue,
    subOrganizations,
    organizationsValue,
    setUpAdminAndUsersData,
  } = AccessControlContext();

  const { organization, isAdmin } = useAuth();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const user = rows.find((row) => id.toString().includes(`${row.refId}`));

    const isConfirm = await alertService.confirm(
      t("Delete"),
      <Trans
        t={t}
        i18nKey="Are-You-Sure-Want-To-Delete-User"
        values={{ name: user?.email }}
        components={{
          span: <span style={{ color: "var(--red)" }} />,
        }}
      />
    );

    if (user && isConfirm) {
      const result = await UsersService.deleteUser(user.refId);

      if (result) {
        alertService.mixin(t("delete-emission-successfully"));
        setUpAdminAndUsersData(result);
      }
    }
  };

  const handleCancelClick = (id: GridRowId, params: any) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: Member) => {
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const columns: GridColDef<MemberUser>[] = [
    {
      field: "displayName",
      headerName: t("user-display-name"),
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1.5,
      type: "string",
      headerAlign: "left",
      align: "left",
      sortable: false,
      headerClassName: ".MuiDataGrid-columnHeaderTitle",
      renderHeader: (params) => {
        return <Box paddingLeft={"52px"}>{params.colDef.headerName}</Box>;
      },
      colSpan: (value, row) => {
        if (row.refId.includes("suborganizationName")) {
          return 6;
        }
        return undefined;
      },
      valueGetter: (value, row) => {
        if (row.refId.includes("suborganizationName")) {
          return row.suborganizationName ?? undefined;
        }
        return value;
      },
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            gap={"10px"}
            alignItems={"center"}
            paddingLeft={
              !params.row.refId.includes("suborganizationName") ? "52px" : "0"
            }
          >
            {params.row.refId.includes("suborganizationName") ? (
              <img src="/img/buildings-black.svg" alt="icon" />
            ) : (
              <Avatar
                src={`data:image/png;base64,${params.row.userProfile}`}
                {...stringAvatar(params.value ?? "!")}
                sx={{ bgcolor: "var(--gray3)", color: "var(--gray4)" }}
                key={params.row.refId}
              />
            )}
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: t("Email"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      sortable: false,
      minWidth: 200,
      flex: 1.5,
    },
    // {
    //   field: "email",
    //   headerName: t("Email"),
    //   disableColumnMenu: true,
    //   minWidth: 120,
    //   flex: 1,
    //   type: "string",
    //   headerAlign: "left",
    //   align: "left",
    //   sortable: false,
    // },
    {
      field: "role",
      headerName: t("Role"),
      disableColumnMenu: true,
      minWidth: 120,
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
      editable: true,
      type: "singleSelect",
      valueOptions: ["admin", "user"],
      getOptionLabel(value) {
        return t(value);
      },
      valueFormatter: (value: string) => {
        return t(value.toLowerCase());
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      disableColumnMenu: true,
      minWidth: 120,
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
      editable: true,
      type: "singleSelect",
      valueOptions: ["activated", "pending"],
      getOptionLabel(value) {
        return t(value);
      },
      renderCell: (params) => {
        const item = USER_STATUS.find((e) => e.status === params.row.status);
        return (
          <Chip
            size="small"
            label={t(params.row.status)}
            sx={{
              backgroundColor: `${item?.bgColcor ?? null}`,
              color: `${item?.textColor ?? null}`,
              fontSize: "12px",
            }}
          />
        );
      },
    },
    // {
    //   field: "",
    //   headerName: "",
    //   disableColumnMenu: true,
    //   sortable: false,
    //   type: "actions",
    //   width: 50,
    //   getApplyQuickFilterFn: undefined,
    //   getActions: ({ id }) => {
    //     // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    //     // if (isInEditMode) {
    //     //   return [
    //     //     <GridActionsCellItem
    //     //       icon={
    //     //         <SaveIcon
    //     //           sx={{
    //     //             width: "18px",
    //     //           }}
    //     //         />
    //     //       }
    //     //       label="Save"
    //     //       sx={{
    //     //         color: "primary.main",
    //     //       }}
    //     //       onClick={handleSaveClick(id)}
    //     //     />,
    //     //     <GridActionsCellItem
    //     //       icon={
    //     //         <CancelIcon
    //     //           sx={{
    //     //             width: "18px",
    //     //           }}
    //     //         />
    //     //       }
    //     //       label="Cancel"
    //     //       className="textPrimary"
    //     //       onClick={handleCancelClick(id)}
    //     //       color="inherit"
    //     //     />,
    //     //   ];
    //     // }

    //     return [
    //       // <GridActionsCellItem
    //       //   icon={<img src="/img/edit-2.svg" alt="icon-edit" />}
    //       //   label="Edit"
    //       //   className="textPrimary"
    //       //   onClick={handleEditClick(id)}
    //       //   color="inherit"
    //       //   key={`${id}-icon-edit`}
    //       //   id={`${id}-icon-edit`}
    //       // />,
    //       <GridActionsCellItem
    //         icon={
    //           <img
    //             src="/img/delete.svg"
    //             alt="icon-delete"
    //             height={"18px"}
    //             width={"18px"}
    //             color="error"
    //           />
    //         }
    //         label="Delete"
    //         onClick={handleDeleteClick(id)}
    //         color="inherit"
    //         key={`${id}-icon-delete`}
    //         id={`${id}-icon-delete`}
    //         disabled={!isAdmin()}
    //         sx={{
    //           opacity: !isAdmin() ? 0.25 : 1,
    //         }}
    //       />,
    //     ];
    //   },
    // },
  ];

  const [rows, setRows] = useState<MemberUser[]>([...usersRows]);

  useEffect(() => {
    filter();
  }, [usersRows, statusValue, roleValue, organizationsValue]);

  const filter = () => {
    let filter = [...usersRows];

    let result: MemberUser[] = [];

    const subOrganization = subOrganizations.find(
      (e) => e.refId === organizationsValue
    );

    if (subOrganization) {
      filter = filter.filter(
        (e) => e.suborganizationId === Number(subOrganization.id)
      );
    }

    if (statusValue !== "all") {
      filter = filter.filter((e) => e.status === statusValue);
    }

    if (filter.length !== usersRows.length) {
      filter.forEach((e, index) => {
        // if (
        //   !result.find((r) => r.suborganizationName === e.suborganizationName)
        // ) {
        //   result.push({
        //     refId: `suborganizationName${index}`,
        //     username: "",
        //     email: "",
        //     status: "",
        //     role: "",
        //     roleId: 0,
        //     suborganizationName: e.suborganizationName,
        //     suborganizationId: e.suborganizationId,
        //   });
        // }

        result.push(e);
      });

      setRows(result);
    } else {
      setRows([...usersRows]);
    }
  };

  return roleValue !== "admin" ? (
    <>
      <Box width={"100%"}>
        <Box borderLeft={3} paddingLeft={"10px"} marginBottom={"8px"}>
          <Typography variant="h1">{t("user")}</Typography>
        </Box>

        <Box>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowHeight={(params) =>
              params.id.toString().includes("suborganizationName") ? 48 : 66
            }
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            pagination={undefined}
            autoHeight
            getRowId={(row) => {
              return row.refId + row.suborganizationId;
            }}
            localeText={{
              noRowsLabel: t("noRowsLabel"),
              noResultsOverlayLabel: t("noResultsOverlayLabel"),
            }}
            sx={{
              ...tableStyle(),
              "& .suborganizationName": {
                backgroundColor: "var(--gray3)",
                height: "44px",
              },
              "& .MuiDataGrid-cell": { padding: "12px !important" },
            }}
            getRowClassName={(params) =>
              params.row.refId.includes("suborganizationName")
                ? "suborganizationName"
                : ""
            }
            editMode="row"
            rowModesModel={rowModesModel}
            processRowUpdate={processRowUpdate}
            onRowEditStop={handleRowEditStop}
            pageSizeOptions={[rows.length]}
            slots={{
              pagination: () => <PaginationForAccessControl rows={rows} />,
            }}
          />
        </Box>
      </Box>
    </>
  ) : null;
};
