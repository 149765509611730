// src/services/alertService.ts
import Swal, { SweetAlertIcon } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "./alert.css";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";

const MySwal = withReactContent(Swal);

const alertService = {
  success: async (message: string, title?: string) => {
    await MySwal.fire({
      title: title || t("Successfully"),
      text: message,
      icon: "success",
      showCloseButton: true,
      confirmButtonColor: "var(--dark)",
      confirmButtonText: t("Ok"),
    });
  },
  error: async (message: string, title?: string, html?: any) => {
    await MySwal.fire({
      title: title || t("Error-Message"),
      text: message,
      icon: "error",
      showCloseButton: true,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "var(--dark)",
      html: html,
    });
  },
  warning: (message: string, title?: string) => {
    MySwal.fire({
      title: title || "Warning",
      text: message,
      icon: "warning",
      showCloseButton: true,
      confirmButtonColor: "var(--dark)",
    });
  },
  info: (message: string, title?: string) => {
    MySwal.fire({
      title: title || undefined,
      text: message,
      icon: "info",
      showConfirmButton: false,
      showCloseButton: true,
    });
  },
  confirm: (
    title: any,
    body: any,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    showConfirmButton?: boolean
  ) => {
    return MySwal.fire({
      showCloseButton: true,
      confirmButtonText: confirmButtonText || t("Yes"),
      confirmButtonColor: confirmButtonColor || "var(--dark)",
      showConfirmButton: showConfirmButton ?? true,
      html: (
        <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              textAlign: "left",
            }}
          >
            {body}
          </Typography>
        </Box>
      ),
    }).then((value: any) => {
      return value.isConfirmed;
    });
  },
  mixin: (title: string, icon: SweetAlertIcon = "success") => {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast: any) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    return Toast.fire({
      icon: icon,
      title: title,
    });
  },
  showLoading: () => {
    return Swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  },
  closeLoading: () => {
    return Swal.close();
  },
};

export default alertService;
