import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { EcoEfficiencyService } from "../service/ecoEfficiencyService";
import { EconomicIndicator } from "../models/dto/response/ResponseCreateEconomicIndicators";

type EconomicIndicatorContextState = {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  getEconomicIndicators: () => void;
  economicIndicators: EconomicIndicator[];
  setEconomicIndicators: Dispatch<SetStateAction<EconomicIndicator[]>>;
  editData: EconomicIndicator | undefined;
  setEditData: Dispatch<SetStateAction<EconomicIndicator | undefined>>;
};

export const Context = createContext<EconomicIndicatorContextState>(
  {} as EconomicIndicatorContextState
);

export const EconomicIndicatorProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const [economicIndicators, setEconomicIndicators] = useState<
    EconomicIndicator[]
  >([]);

  const [editData, setEditData] = useState<EconomicIndicator | undefined>();

  const getEconomicIndicators = async () => {
    const result = await EcoEfficiencyService.getEconomicIndicators();

    if (result) {
      setEconomicIndicators(result);
    }
  };

  useEffect(() => {
    getEconomicIndicators();
  }, []);

  return (
    <Context.Provider
      value={{
        modalIsOpen,
        setModalIsOpen,
        getEconomicIndicators,
        economicIndicators,
        setEconomicIndicators,
        editData,
        setEditData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const EconomicIndicatorContext = () => useContext(Context);
