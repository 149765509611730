import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MAX_UPLOAD_FILE_SIZE } from "../../constants/form";
import FileUtils, { FilePreview } from "../../utils/fileUtlis";
import alertService from "../alert/alertService";
import { Trans, useTranslation } from "react-i18next";
import Grid2 from "@mui/material/Unstable_Grid2";
import InfoPopper from "../popper/InfoPopper";

type Props = {
  attachment: string;
  setValue: (value: string) => void;
  headline: string;
  description: string;
};

export const UploadFileZone = (props: Props) => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const [files, setFiles] = useState<FilePreview[]>([]);

  const previewFile = () => {
    window.open(files[0].preview);
  };

  const deleteFile = () => {
    setFiles([]);
    props.setValue("");
    setError(undefined);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropRejected(fileRejections, event) {
      if (fileRejections[0].errors[0].code === "file-too-large") {
        return alertService.error(
          t("maximum-file-size", {
            size: (MAX_UPLOAD_FILE_SIZE / 1e6).toFixed(0),
          })
        );
      } else {
        return alertService.error(t("Invalid-File-Type"));
      }
    },
    async onDrop(acceptedFiles, fileRejections, event) {
      const filePreviews = acceptedFiles.map((file) => ({
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
      }));

      setFiles(filePreviews);

      await FileUtils.getBase64(acceptedFiles[0])
        .then((res) => {
          props.setValue(res as string);
          setError(undefined);
        })
        .catch((err) => {
          setFiles([]);
          props.setValue("");
        });
    },
    maxFiles: 1,
    maxSize: MAX_UPLOAD_FILE_SIZE,
    multiple: false,
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
  });

  const [error, setError] = useState<String | undefined>();

  useEffect(() => {
    try {
      const attachment = props.attachment ?? "";

      if (attachment !== "") {
        const file: File = FileUtils.base64ToFile(attachment);

        const filePreviews = {
          name: file.name,
          size: file.size,
          preview: URL.createObjectURL(file),
        };

        setFiles([filePreviews]);
        setError(undefined);
      }
    } catch (error) {
      setError(props.attachment);
      alertService.error(t("Cannot-Preview-Evidence"));
    }
  }, [props.attachment]);

  return (
    <Box minHeight={"110px"} width={"100%"}>
      <Typography
        variant="h3"
        color={"var(--dark)"}
        sx={{
          marginBottom: "8px",
          borderBlockStart: "1px",
        }}
      >
        {`| ${props.headline}`}

        <InfoPopper text={props.description} />
      </Typography>

      {error ? (
        <Box
          display={"flex"}
          columnGap={"14px"}
          alignItems={"center"}
          sx={{
            border: "1px solid var(--gray4)",
            padding: "8px",
            borderRadius: "8px",
          }}
          width={{ xs: "auto", md: "40%" }}
        >
          <Typography
            variant="text1"
            flex={1}
            style={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
            key={"error-item"}
          >
            {error}
          </Typography>

          <IconButton
            color="error"
            aria-label="delete"
            onClick={deleteFile}
            sx={{
              backgroundColor: "var(--status-rejected-failed-bg)",
              "&:hover": {
                backgroundColor: "var(--status-rejected-failed-bg)",
                opacity: 0.75,
              },
            }}
          >
            <img src="/img/delete.svg" alt="icon-delete" />
          </IconButton>
        </Box>
      ) : files.length > 0 ? (
        <Box
          display={"flex"}
          columnGap={"14px"}
          alignItems={"center"}
          sx={{
            border: "1px solid var(--gray4)",
            padding: "8px",
            borderRadius: "8px",
          }}
          width={{ xs: "auto", md: "40%" }}
        >
          {files.map((file, index) => (
            <Typography
              variant="text1"
              flex={1}
              style={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
              key={file.name}
            >
              {file.name}
            </Typography>
          ))}
          <IconButton
            color="info"
            aria-label="previewFile"
            onClick={previewFile}
            sx={{
              backgroundColor: "var(--Scope-1-Refrigerant)",
              "&:hover": {
                backgroundColor: "var(--Scope-1-Refrigerant)",
                opacity: 0.75,
              },
            }}
          >
            <img src="/img/eye.svg" alt="preview" />
          </IconButton>
          <IconButton
            color="error"
            aria-label="delete"
            onClick={deleteFile}
            sx={{
              backgroundColor: "var(--status-rejected-failed-bg)",
              "&:hover": {
                backgroundColor: "var(--status-rejected-failed-bg)",
                opacity: 0.75,
              },
            }}
          >
            <img src="/img/delete.svg" alt="icon-delete" />
          </IconButton>
        </Box>
      ) : (
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed grey",
            borderRadius: "8px",
            padding: "16px",
            textAlign: "center",
            cursor: "pointer",
            backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
            "&:hover": { backgroundColor: "#f0f0f0" },
          }}
        >
          <input {...getInputProps()} />

          <Box color={"var(--gray4)"}>
            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey="drag-drop-or-browse"
                components={{
                  span: <span style={{ color: "var(--green)" }} />,
                }}
              />
            </Typography>
            <Typography variant="body1">
              <Trans
                t={t}
                i18nKey="maximum-file-size"
                values={{
                  size: (MAX_UPLOAD_FILE_SIZE / 1e6).toFixed(0),
                }}
              />
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
