import { Box, FormControl, Grid, IconButton, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import InfoPopper from "../../../../components/popper/InfoPopper";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CustomInput from "../../../../components/input/CustomInput";
import FileUtils, { FilePreview } from "../../../../utils/fileUtlis";
import alertService from "../../../../components/alert/alertService";
import { MAX_UPLOAD_FILE_SIZE } from "../../../../constants/form";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../contexts/AddEmissionContext";
import { UploadFileZone } from "../../../../components/input/UploadFileZone";

const AddEmissionSectionTwo: React.FC = () => {
  const { t } = useTranslation(["common", "overview"], {
    nsMode: "fallback",
  });

  const { stepTwoForm, emissionMode } = AddEmissionContext();

  const attachment = stepTwoForm.watch("attachment");

  // const [files, setFiles] = useState<FilePreview[]>([]);

  // const previewFile = () => {
  //   window.open(files[0].preview);
  // };

  // const deleteFile = () => {
  //   setFiles([]);
  //   stepTwoForm.setValue("attachment", "");
  // };

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDropRejected(fileRejections, event) {
  //     if (fileRejections[0].errors[0].code === "file-too-large") {
  //       return alertService.error(
  //         t("maximum-file-size", {
  //           size: (MAX_UPLOAD_FILE_SIZE / 1e6).toFixed(0),
  //         })
  //       );
  //     } else {
  //       return alertService.error(t("Invalid-File-Type"));
  //     }
  //   },
  //   async onDrop(acceptedFiles, fileRejections, event) {
  //     const filePreviews = acceptedFiles.map((file) => ({
  //       name: file.name,
  //       size: file.size,
  //       preview: URL.createObjectURL(file),
  //     }));

  //     setFiles(filePreviews);

  //     await FileUtils.getBase64(acceptedFiles[0])
  //       .then((res) => {
  //         stepTwoForm.setValue("attachment", res as string);
  //       })
  //       .catch((err) => {
  //         setFiles([]);
  //         stepTwoForm.setValue("attachment", "");
  //       });
  //   },
  //   maxFiles: 1,
  //   maxSize: MAX_UPLOAD_FILE_SIZE,
  //   multiple: false,
  //   accept: {
  //     "image/*": [],
  //     "application/pdf": [],
  //   },
  // });

  // useEffect(() => {
  //   try {
  //     const attachment = stepTwoForm.getValues("attachment") ?? "";

  //     if (attachment !== "") {
  //       const file: File = FileUtils.base64ToFile(attachment);

  //       const filePreviews = {
  //         name: file.name,
  //         size: file.size,
  //         preview: URL.createObjectURL(file),
  //       };

  //       setFiles([filePreviews]);
  //     }
  //   } catch (error) {
  //     alertService.error(t("Cannot-Preview-Evidence"));
  //   }
  // }, []);

  return (
    <>
      {emissionMode === EmissionMode.AddEmission ? (
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="remark"
                defaultValue=""
                control={stepTwoForm.control}
                rules={{
                  required: false,
                  maxLength: 255,
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    label={t("Remark")}
                    info={t("Remark")}
                    placeholder={t("Remark")}
                    value={value}
                    handleOnChange={onChange}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Controller
            name="attachment"
            defaultValue=""
            control={stepTwoForm.control}
            render={({ field: { onChange, value } }) => (
              <input value={value} onChange={onChange} type="hidden" />
            )}
          />

          <Grid item xs={12}>
            <UploadFileZone
              attachment={attachment}
              setValue={(value) => stepTwoForm.setValue("attachment", value)}
              headline={`${t("Evidence-File")}`}
              description={t("Attachment-Description")}
            />
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default AddEmissionSectionTwo;
