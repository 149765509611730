import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Layout from "../../../Layout";
import {
  tableStyle,
  VisuallyHiddenInput,
} from "../../emission/data-table/EmissionDataTableStyle";
import { CustomPagination } from "../../../components/datagrid/CustomPagination";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { QuickSearchToolbar } from "./QuickSearchToolbar";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import alertService from "../../../components/alert/alertService";
import { MAX_UPLOAD_FILE_SIZE } from "../../../constants/form";
import i18n from "../../../i18n";
import FileUtils from "../../../utils/fileUtlis";
import {
  EconomicIndicatorContext,
  EconomicIndicatorProvider,
} from "../../../contexts/EconomicIndicatorContext";
import { TableEconomicIndicator } from "./TableEconomicIndicator";

export const EconomicIndicator = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  return (
    <EconomicIndicatorProvider>
      <Layout>
        <Box
          padding={"40px"}
          bgcolor={"var(--white)"}
          height={"100%"}
          boxSizing={"border-box"}
        >
          <TableEconomicIndicator />
        </Box>
      </Layout>
    </EconomicIndicatorProvider>
  );
};
