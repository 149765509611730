import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CustomPagination } from "../../components/datagrid/CustomPagination";
import { tableStyle } from "../emission/data-table/EmissionDataTableStyle";
import { QuickSearchToolbar } from "./QuickSearchToolbar";
import dayjs, { Dayjs } from "dayjs";
import { EcoEfficiencyContext } from "../../contexts/EcoEfficiencyContext";
import { EcoEfficiency } from "../../models/dto/response/ResponseEcoEfficiency";
import { EcoEfficiencyService } from "../../service/ecoEfficiencyService";
import alertService from "../../components/alert/alertService";
import InfoPopper from "../../components/popper/InfoPopper";

export const EcoEfficiencyDataGrid = () => {
  const { t, i18n } = useTranslation(["common", "input", "economic"], {
    nsMode: "fallback",
  });

  const {
    ecoEfficiency,
    setEcoEfficiency,
    setEditData,
    setModalIsOpen,
    filterSubOrganization,
  } = EcoEfficiencyContext();

  const [rowSelect, setRowSelect] = useState<EcoEfficiency | undefined>();

  // popper
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openPopper = Boolean(anchorEl);

  const handleOnClickPopper = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (event?: any) => {
    setAnchorEl(null);

    if (rowSelect) {
      if (event.target.id === "edit") {
        setModalIsOpen(true);
        setEditData(rowSelect);
      } else if (event.target.id === "deleted") {
        const isConfirm = await alertService.confirm(
          t("Delete"),
          <Trans
            t={t}
            i18nKey="Are-You-Sure-To-Delete-Eco-Efficiency"
            values={{ runningNo: rowSelect.id }}
            components={{
              span: <span style={{ color: "var(--red)" }} />,
            }}
          />
        );

        if (isConfirm) {
          const refId: string = rowSelect._id.toString();

          const result = await EcoEfficiencyService.deleteEcoEfficiency(refId);

          if (result) {
            setEcoEfficiency(result);
          }
        }
      }
    }
  };

  const [rows, setRows] = useState<EcoEfficiency[]>([]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("ID"),
      width: 80,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value) => `EE-${value}`,
    },
    {
      field: "startDate",
      headerName: t("Period"),
      minWidth: 100,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value: any, row: any) => {
        const start: Dayjs = dayjs(value.substring(0, 10));

        const end: Dayjs = dayjs(row.endDate.substring(0, 10));

        if (start.isSame(end, "month")) {
          return start.locale(i18n.language).format("MMM YYYY");
        } else {
          return `${start.locale(i18n.language).format("MMM YYYY")} -\n${end
            .locale(i18n.language)
            .format("MMM YYYY")}`;
        }
      },
      renderCell: (params) => {
        return (
          <Typography variant="text1" sx={{ whiteSpace: "pre-line" }}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "suborganizationRef",
      headerName: t("Sub-Organizations"),
      headerAlign: "left",
      minWidth: 140,
      flex: 1,
      valueFormatter: (value: any, row: EcoEfficiency) =>
        row.suborganizationRef.name,
    },
    {
      field: "indicatorRef",
      headerName: t("Indicator-Name-Table"),
      headerAlign: "left",
      minWidth: 160,
      flex: 1,
      valueFormatter: (value: any, row: EcoEfficiency) =>
        i18n.language === "th"
          ? row.indicatorRef.nameTh
          : row.indicatorRef.nameEn,
    },
    {
      field: "economicValue",
      headerName: t("Economic-Value"),
      headerAlign: "right",
      minWidth: 130,
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <span className="table-cell-trucate">
              {params.row.economicValue}
            </span>
            <InfoPopper
              text={`${params.row.environmentalImpactType} (kgCO2e)`}
            />
          </Box>
        );
      },
    },
    {
      field: "environmentalImpactValue",
      headerName: t("Environment-Impact-Value"),
      headerAlign: "right",
      minWidth: 220,
      type: "number",
      flex: 1,
    },
    {
      field: "ecoEfficiency",
      headerName: t("Eco-Efficiency"),
      headerAlign: "right",
      minWidth: 180,
      type: "number",
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("Unit"),
      minWidth: 80,
      type: "string",
      valueFormatter: (value: any, row: EcoEfficiency) => row.indicatorRef.unit,
    },
    {
      field: "baseYear",
      headerName: t("Base-Year"),
      minWidth: 80,
      type: "string",
    },
    // {
    //   field: "lastCalculatedAt",
    //   headerName: t("Last-Calculation"),
    //   minWidth: 140,
    //   type: "string",
    //   valueFormatter: (value: string) =>
    //     dayjs(value.substring(0, 10)).format("DD/MM/YYYY"),
    // },
    {
      field: "",
      headerName: "",
      sortable: false,
      type: "actions",
      width: 80,
      getApplyQuickFilterFn: undefined,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key={`${params.row.id}-refresh`}
            icon={<img src="/img/refresh.svg" alt="icon-refresh" />}
            label="more"
            id={`${params.row.id}-refresh`}
            onClick={async (event) => {
              const result =
                await EcoEfficiencyService.recalculateEcoEfficiency(
                  params.row._id
                );

              if (result) {
                alertService.mixin(t("Eco-Efficiency-Recalculate-Success"));
                setEcoEfficiency(result);
              }
            }}
          />,

          <GridActionsCellItem
            key={`${params.row.id}-more`}
            icon={<img src="/img/more.svg" alt="icon-more" />}
            label="more"
            id="basic-button"
            aria-controls={openPopper ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openPopper ? "true" : undefined}
            onClick={(event) => {
              setRowSelect(params.row);
              handleOnClickPopper(event);
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (filterSubOrganization) {
      setRows([
        ...ecoEfficiency.filter((e) =>
          filterSubOrganization.includes(e.suborganizationRef.name)
        ),
      ]);
    } else {
      setRows([...ecoEfficiency]);
    }
  }, [ecoEfficiency, filterSubOrganization]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      maxHeight={window.innerHeight - 53 > 440 ? window.innerHeight - 53 : 440}
    >
      <Menu
        anchorEl={anchorEl}
        open={openPopper}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose} id="edit">
          {t("Edit")}
        </MenuItem>
        {/* <MenuItem onClick={handleClose} id="duplicate">
          {t("Duplicate")}
        </MenuItem> */}

        <Divider />
        <MenuItem
          id="deleted"
          onClick={handleClose}
          sx={{
            color: "var(--red)",
          }}
        >
          {t("Delete")}
        </MenuItem>
      </Menu>

      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={60}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        slots={{
          toolbar: QuickSearchToolbar,
          pagination: CustomPagination,
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: `${t("search")}...`,
          noRowsLabel: t("noRowsLabel"),
          noResultsOverlayLabel: t("noResultsOverlayLabel"),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              variant: "outlined",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        sx={tableStyle()}
      />
    </Box>
  );
};
