import {
  Box,
  Button,
  Popover,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomModal from "../../../components/modal/CustomModal";
import { useAuth } from "../../../contexts/UserContext";
import { OrganizationBreadcrumbs } from "../../emission/OrganizationBreadcrumbs";
import { EconomicIndicatorContext } from "../../../contexts/EconomicIndicatorContext";
import Grid2 from "@mui/material/Unstable_Grid2";
import { InputReactHookForm } from "../../../components/input/InputReactHookForm";
import { Trans, useTranslation } from "react-i18next";
import InfoPopper from "../../../components/popper/InfoPopper";
import { useDropzone } from "react-dropzone";
import alertService from "../../../components/alert/alertService";
import { useEffect, useState } from "react";
import { MAX_UPLOAD_FILE_SIZE } from "../../../constants/form";
import FileUtils, { FilePreview } from "../../../utils/fileUtlis";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModes,
  GridRowModesModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { tableStyle } from "../../emission/data-table/EmissionDataTableStyle";
import { EcoEfficiencyService } from "../../../service/ecoEfficiencyService";
import { UploadFileZone } from "../../../components/input/UploadFileZone";
import BaseYearSelect from "./BaseYearSelect";

type AddEconomicIndicatorFormValues = {
  nameTh: string;
  nameEn: string;
  unit: string;
  isRequireBaseYear: boolean;
  attachment: string;
  components: any[];
};

type Component = {
  id: number;
  nameTh: string;
  nameEn: string;
  unit: string;
};

const regexEn = /^(?=.*[A-Za-z0-9])[A-Za-z0-9\s\-\_\(\)\.]*$/;

const regexTh = /^[\u0E00-\u0E7F0-9\s\-\_\(\)\.]*$/;

// const regexTh = /(?=.*[\u0E00-\u0E7F0-9])[\u0E00-\u0E7F0-9\s\-\_\(\).]*$/;

export const AddEconomicIndicator2 = () => {
  const { t } = useTranslation(["common", "economic", "report"], {
    nsMode: "fallback",
  });

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<AddEconomicIndicatorFormValues>({
    reValidateMode: "onChange",
    mode: "all",
  });

  const attachment = watch("attachment");

  const isRequireBaseYear = watch("isRequireBaseYear");

  const { organization } = useAuth();

  const {
    modalIsOpen,
    setModalIsOpen,
    setEconomicIndicators,
    setEditData,
    editData,
  } = EconomicIndicatorContext();

  const openModal = () => {
    setEditData(undefined);

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setRows([]);

    setRowModesModel({});

    reset();

    setEditData(undefined);

    setModalIsOpen(false);
  };

  // start data grid

  const apiRef = useGridApiRef();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const processRowUpdate = async (newRow: Component, oldRow: Component) => {
    const updatedRow = apiRef.current.getRow(newRow.id) as Component;

    // Validate the updated data
    const isValidTh = regexTh.test(updatedRow.nameTh);
    const isValidEn = regexEn.test(updatedRow.nameEn);
    const isValidUnit = regexEn.test(updatedRow.unit);

    if (!isValidTh || !isValidEn || !isValidUnit) {
      if (!isValidTh) {
        alertService.mixin(
          `CI-${updatedRow.id} : ${t("Component")} (${t("Thai")})\n\n${t(
            "Only-Thai-letters-are-allowed"
          )}`,
          "info"
        );
      }
      if (!isValidEn) {
        alertService.mixin(
          `CI-${updatedRow.id} : ${t("Component")} (${t("English")})\n\n${t(
            "Only-English-letters-are-allowed"
          )}`,
          "info"
        );
      }
      if (!isValidUnit) {
        alertService.mixin(
          `CI-${updatedRow.id} : ${t("Unit")}\n\n${t(
            "Only-English-letters-are-allowed"
          )}`,
          "info"
        );
      }
      return oldRow;
    }

    setRows((prevRows) =>
      prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );

    return updatedRow;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View },
      });
    }
  };

  const handleDeleteRow = (id: number) => {
    setRows((prevRows) => {
      const updatedRows = prevRows
        .filter((row) => row.id !== id)
        .map((row, index) => ({
          ...row,
          id: index + 1, // Reindex the remaining rows
        }));
      return updatedRows;
    });
  };

  const handleAddClick = () => {
    if (rows.length >= 5) {
      alertService.mixin(t("Maximum-5-components-allowed"), "info");
      return;
    }
    const id: any = rows.length + 1;
    setRows((oldRows: any) => [
      ...oldRows,
      { id: id, nameEn: "", nameTh: "", unit: "" },
    ]);
  };

  const [rows, setRows] = useState<Component[]>([]);

  const columns: GridColDef<Component>[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      width: 80,
      valueFormatter: (value: number) => `CI-${value}`,
    },
    {
      field: "nameTh",
      headerName: `${t("Component")} (${t("Thai")})`,
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        const handleBlur = () => {
          const currentValue = params.row.nameTh;
          if (currentValue && !regexTh.test(currentValue)) {
            alertService.mixin(
              `CI-${params.id} : ${t("Component")} (${t("Thai")})\n\n${t(
                "Only-Thai-letters-are-allowed"
              )}`,
              "info"
            );
            // Reset the value if invalid
            setRows((prevRows) =>
              prevRows.map((row) =>
                row.id === params.id ? { ...row, nameTh: "" } : row
              )
            );
            apiRef.current.updateRows([
              {
                id: params.id,
                nameTh: "",
              },
            ]);
          }
        };
        return (
          <Box sx={{ width: "100%", position: "relative" }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              autoComplete="off"
              onBlur={handleBlur}
              value={params.row.nameTh || ""}
              onChange={(e) => {
                const newValue = e.target.value;
                const isValid = regexTh.test(newValue);
                const errorEl = document.getElementById(
                  `error-text-${params.id}`
                );
                if (errorEl) {
                  errorEl.style.opacity = isValid ? "0" : "1";
                }

                apiRef.current.updateRows([
                  {
                    id: params.id,
                    nameTh: newValue,
                  },
                ]);

                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id ? { ...row, nameTh: newValue } : row
                  )
                );
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
            <FormHelperText
              id={`error-text-${params.id}`}
              error
              sx={{
                position: "absolute",
                bottom: -20,
                opacity: 0,
              }}
            >
              {t("Only-Thai-letters-are-allowed")}
            </FormHelperText>
          </Box>
        );
      },
    },
    {
      field: "nameEn",
      headerName: `${t("Component")} (${t("English")})`,
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        const handleBlur = () => {
          const currentValue = params.row.nameEn;
          if (currentValue && !regexEn.test(currentValue)) {
            alertService.mixin(
              `CI-${params.id} : ${t("Component")} (${t("English")})\n\n${t(
                "Only-English-letters-are-allowed"
              )}`,
              "info"
            );
            // Reset the value if invalid
            setRows((prevRows) =>
              prevRows.map((row) =>
                row.id === params.id ? { ...row, nameEn: "" } : row
              )
            );
            apiRef.current.updateRows([
              {
                id: params.id,
                nameEn: "",
              },
            ]);
          }
        };
        return (
          <Box sx={{ width: "100%", position: "relative" }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              autoComplete="off"
              onBlur={handleBlur}
              value={params.row.nameEn || ""}
              onChange={(e) => {
                const newValue = e.target.value;
                const isValid = regexEn.test(newValue);
                const errorEl = document.getElementById(
                  `error-text-en-${params.id}`
                );
                if (errorEl) {
                  errorEl.style.opacity = isValid ? "0" : "1";
                }

                apiRef.current.updateRows([
                  {
                    id: params.id,
                    nameEn: newValue,
                  },
                ]);

                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id ? { ...row, nameEn: newValue } : row
                  )
                );
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
            <FormHelperText
              id={`error-text-en-${params.id}`}
              error
              sx={{
                position: "absolute",
                bottom: -20,
                opacity: 0,
              }}
            >
              {t("Only-English-letters-are-allowed")}
            </FormHelperText>
          </Box>
        );
      },
    },
    {
      field: "unit",
      headerName: `${t("Unit")} (${t("English")})`,
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        const handleBlur = () => {
          const currentValue = params.row.unit;
          if (currentValue && !regexEn.test(currentValue)) {
            alertService.mixin(
              `CI-${params.id} : ${t("Unit")}\n\n${t(
                "Only-English-letters-are-allowed"
              )}`,
              "info"
            );
            // Reset the value if invalid
            setRows((prevRows) =>
              prevRows.map((row) =>
                row.id === params.id ? { ...row, unit: "" } : row
              )
            );
            apiRef.current.updateRows([
              {
                id: params.id,
                unit: "",
              },
            ]);
          }
        };
        return (
          <Box sx={{ width: "100%", position: "relative" }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              autoComplete="off"
              onBlur={handleBlur}
              value={params.row.unit || ""}
              onChange={(e) => {
                const newValue = e.target.value;
                const isValid = regexEn.test(newValue);
                const errorEl = document.getElementById(
                  `error-text-unit-${params.id}`
                );
                if (errorEl) {
                  errorEl.style.opacity = isValid ? "0" : "1";
                }

                apiRef.current.updateRows([
                  {
                    id: params.id,
                    unit: newValue,
                  },
                ]);

                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id ? { ...row, unit: newValue } : row
                  )
                );
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
            <FormHelperText
              id={`error-text-unit-${params.id}`}
              error
              sx={{
                position: "absolute",
                bottom: -20,
                opacity: 0,
              }}
            >
              {t("Only-English-letters-are-allowed")}
            </FormHelperText>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      type: "actions",
      width: 80,
      renderCell: (params) => (
        <Button
          variant="text"
          color="error"
          onClick={() => handleDeleteRow(params.row.id)}
          sx={{
            minWidth: "auto",
            padding: "6px",
            "&:hover": {
              backgroundColor: "rgba(211, 47, 47, 0.04)",
            },
          }}
        >
          <img
            src="/img/delete.svg"
            alt="delete"
            height={"18px"}
            width={"18px"}
            color="error"
          />
        </Button>
      ),
    },
  ];

  // const OnSubmit = async (data: AddEconomicIndicatorFormValues) => {
  //   if (apiRef.current) {
  //     // Get the current rows directly from our state
  //     const temp = rows.filter(
  //       (r) =>
  //         r.nameEn.trim() !== "" &&
  //         r.nameTh.trim() !== "" &&
  //         r.unit.trim() !== ""
  //     );

  //     // Map the components to the expected format
  //     data.components = temp.map((r) => ({
  //       nameEn: r.nameEn,
  //       nameTh: r.nameTh,
  //       unit: r.unit,
  //     }));

  //     let result;

  //     if (editData === undefined) {
  //       // add mode
  //       result = await EcoEfficiencyService.createEconomicIndicators(data);
  //     } else {
  //       // edit mode
  //       result = await EcoEfficiencyService.editEconomicIndicators(
  //         editData._id,
  //         data
  //       );
  //     }

  //     if (result) {
  //       setEconomicIndicators(result);
  //       alertService.mixin(t("Create-Economic-Indicator-Success"));
  //       closeModal();
  //     }
  //   }
  // };

  const OnSubmit = async (data: AddEconomicIndicatorFormValues) => {
    if (apiRef.current) {
      // Check if there are any rows
      if (rows.length === 0) {
        alertService.mixin(t("Economic-Indicator-Required"), "error");
        return;
      }

      // Validate all rows for completeness and regex patterns
      const incompleteRows: number[] = [];
      const invalidThRows: number[] = [];
      const invalidEnRows: number[] = [];
      const invalidUnitRows: number[] = [];

      rows.forEach((row) => {
        if (!row.nameEn.trim() || !row.nameTh.trim() || !row.unit.trim()) {
          incompleteRows.push(row.id);
        } else {
          // Only check regex if fields are not empty
          if (!regexTh.test(row.nameTh.trim())) {
            invalidThRows.push(row.id);
          }
          if (!regexEn.test(row.nameEn.trim())) {
            invalidEnRows.push(row.id);
          }
          if (!regexEn.test(row.unit.trim())) {
            invalidUnitRows.push(row.id);
          }
        }
      });

      // // Show validation errors if any
      if (incompleteRows.length > 0) {
        alertService.mixin(
          `${t(
            "Please-complete-all-fields-for-components"
          )}: CI-${incompleteRows.join(", CI-")}`,
          "error"
        );
        return;
      }

      // if (invalidThRows.length > 0) {
      //   alertService.mixin(
      //     `${t("Invalid-Thai-characters-in-components")}: IE-${invalidThRows.join(", IE-")}`,
      //     "error"
      //   );
      //   return;
      // }

      // if (invalidEnRows.length > 0) {
      //   alertService.mixin(
      //     `${t("Invalid-English-characters-in-components")}: IE-${invalidEnRows.join(", IE-")}`,
      //     "error"
      //   );
      //   return;
      // }

      // if (invalidUnitRows.length > 0) {
      //   alertService.mixin(
      //     `${t("Invalid-unit-format-in-components")}: IE-${invalidUnitRows.join(", IE-")}`,
      //     "error"
      //   );
      //   return;
      // }

      // If all validations pass, filter and prepare data
      const validComponents = rows.map((r) => ({
        nameEn: r.nameEn.trim(),
        nameTh: r.nameTh.trim(),
        unit: r.unit.trim(),
      }));

      data.components = validComponents;

      let result;
      try {
        if (editData === undefined) {
          // add mode
          result = await EcoEfficiencyService.createEconomicIndicators(data);
        } else {
          // edit mode
          result = await EcoEfficiencyService.editEconomicIndicators(
            editData._id,
            data
          );
        }

        if (result) {
          setEconomicIndicators(result);
          alertService.mixin(t("Create-Economic-Indicator-Success"));
          closeModal();
        }
      } catch (error) {
        alertService.mixin(t("Error-creating-economic-indicator"), "error");
      }
    }
  };
  const OnError = () => {
    const form: HTMLElement | null = document.getElementById("form");

    form!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  // end data grid

  // start img formula

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [currentSrc, setCurrentSrc] = useState(
    "/img/economic-indicator/with-out-base-year-formula-big.png"
  );
  const [fading, setFading] = useState(false);

  // end img formula

  useEffect(() => {
    // สำหรับโหมด edit
    if (editData) {
      setValue("nameEn", editData.nameEn);

      setValue("nameTh", editData.nameTh);

      setValue("unit", editData.unit);

      setValue("isRequireBaseYear", editData.isRequireBaseYear);

      setValue("attachment", editData.attachment ?? "");

      const components = editData.components.map((c, index) => ({
        id: index + 1,
        nameEn: c.nameEn,
        nameTh: c.nameTh,
        unit: c.unit,
      }));

      setValue("components", components);

      setRows(components);
    }
  }, [editData]);

  useEffect(() => {
    setFading(true);
    setTimeout(() => {
      setCurrentSrc(
        isRequireBaseYear
          ? "/img/economic-indicator/base-year-formula-big.png"
          : "/img/economic-indicator/with-out-base-year-formula-big.png"
      );
      setFading(false);
    }, 300);
  }, [isRequireBaseYear]);

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        startIcon={<img src="/img/add-circle.svg" alt="add-circle" />}
        onClick={openModal}
      >
        {t("Add-Economic-Indicator")}
      </Button>

      <CustomModal
        header={
          <Typography variant="h1">
            {" "}
            {editData
              ? t("Edit-Economic-Indicator")
              : t("Add-Economic-Indicator")}
          </Typography>
        }
        open={modalIsOpen}
        onClose={closeModal}
        body={
          <>
            <Box marginY={"20px"}>
              <OrganizationBreadcrumbs
                organization={organization?.name ?? ""}
                location={""}
                subOrganizations={""}
              />
            </Box>

            <form onSubmit={handleSubmit(OnSubmit, OnError)} id="form">
              <Grid2
                container
                columnSpacing={{ xs: "10px", md: "40px" }}
                rowSpacing={"20px"}
                maxWidth={"100%"}
                paddingTop={"24px"}
                disableEqualOverflow
              >
                <Grid2 xs={12} md={6}>
                  <InputReactHookForm
                    required
                    label={t("Indicator-Name-En")}
                    placeholder={t("Indicator-Name-En")}
                    info={`${t("Indicator-Name")} (${t("English")})`}
                    name="nameEn"
                    control={control}
                    rules={{
                      required: t("input-reqired-error", {
                        param: t("Indicator-Name-En"),
                      }),
                      pattern: {
                        value: regexEn,
                        message: t("Only-English-letters-are-allowed"),
                      },
                    }}
                    defaultValue=""
                  />
                </Grid2>

                <Grid2 xs={12} md={6}>
                  <InputReactHookForm
                    required
                    label={`${t("Unit")} (${t("English")})`}
                    placeholder={t("Unit")}
                    name="unit"
                    control={control}
                    rules={{
                      required: t("input-reqired-error", {
                        param: t("Unit"),
                      }),
                      pattern: {
                        value: regexEn,
                        message: t("Only-English-letters-are-allowed"),
                      },
                    }}
                    defaultValue=""
                    info={t("Unit")}
                  />
                </Grid2>

                <Grid2 xs={12} md={6}>
                  <InputReactHookForm
                    required
                    label={t("Indicator-Name-Th")}
                    placeholder={t("Indicator-Name-Th")}
                    info={`${t("Indicator-Name")} (${t("Thai")})`}
                    name="nameTh"
                    control={control}
                    rules={{
                      required: t("input-reqired-error", {
                        param: t("Indicator-Name-Th"),
                      }),
                      pattern: {
                        value: regexTh,
                        message: t("Only-Thai-letters-are-allowed"),
                      },
                    }}
                    defaultValue=""
                  />
                </Grid2>

                <Grid2 xs={12} md={6}>
                  <BaseYearSelect
                    control={control}
                    t={t}
                    getValues={getValues}
                  />
                </Grid2>

                <Grid2 xs={12} md={12}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography variant="h3" color={"var(--dark)"}
                    sx={{paddingBottom: "10px"}}
                    >
                      {t("Formula")}
                      {<InfoPopper text={t("Formula-Description")} />}
                    </Typography>
                    <img
                      src={currentSrc}
                      style={{
                        maxHeight: "140px",
                        width: "auto",
                        maxWidth: "100%",
                        opacity: fading ? 0 : 1,
                        transition: "opacity 0.3s ease-out",
                      }}
                      alt="formula"
                      // aria-owns={open ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      // onMouseEnter={handlePopoverOpen}
                      // onMouseLeave={handlePopoverClose}
                    />
                    {/* <Popover
                      id="mouse-over-popover"
                      sx={{ pointerEvents: "none" }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <img
                        src={
                          isRequireBaseYear
                            ? "/img/economic-indicator/base-year-formula-big.png"
                            : "/img/economic-indicator/with-out-base-year-formula-big.png"
                        }
                        alt="formula-big"
                        style={{
                          padding: "10px",
                          width: "-webkit-fill-available",
                          height: "auto",
                          maxWidth: "514px",
                          maxHeight: "208px",
                          transition: ".3s",
                        }}
                      />
                    </Popover> */}
                  </Box>
                </Grid2>

                <Grid2 xs={12} id="component">
                  <Typography
                    variant="h3"
                    color={"var(--dark)"}
                    sx={{
                      marginBottom: "8px",
                      borderBlockStart: "1px",
                    }}
                  >
                    {`| ${t("Component")}`}

                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {" * "}
                    </span>
                    <InfoPopper text={t("Component-Description")} />
                  </Typography>

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"20px"}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid var(--gray2)",
                      padding: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      startIcon={
                        <img src="/img/add-circle.svg" alt="add-circle" />
                      }
                      sx={{
                        maxWidth: "255px",
                        marginLeft: "auto",
                      }}
                      onClick={handleAddClick}
                    >
                      {t("Add-Component")}
                    </Button>

                    <DataGrid
                      apiRef={apiRef}
                      rows={rows}
                      columns={columns}
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableColumnMenu
                      autoHeight
                      rowHeight={80}
                      hideFooter
                      isCellEditable={() => false}
                      isRowSelectable={() => false}
                      localeText={{
                        noRowsLabel: t("noRowsLabel"),
                        noResultsOverlayLabel: t("noResultsOverlayLabel"),
                      }}
                      onCellKeyDown={(params, event, details) => {
                        if (params.field === "nameTh") {
                          event.stopPropagation();
                          return;
                        }
                      }}
                      sx={{
                        ...tableStyle(),
                      }}
                      rowModesModel={rowModesModel}
                      processRowUpdate={processRowUpdate}
                      onRowEditStop={handleRowEditStop}
                    />
                  </Box>
                </Grid2>

                <Controller
                  name="attachment"
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <input value={value} onChange={onChange} type="hidden" />
                  )}
                />

                <Grid2 xs={12} marginY={"20px"}>
                  <UploadFileZone
                    attachment={attachment}
                    setValue={(value) => setValue("attachment", value)}
                    headline={`${t("Evidence-File")}`}
                    description={t("Indicator-Evidence-File-Description")}
                  />
                </Grid2>

                <Grid2 xs={12}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    type="submit"
                    name="add"
                    sx={{
                      marginTop: "48px",
                    }}
                  >
                    {editData === undefined ? t("Add") : t("Save")}
                  </Button>
                </Grid2>
              </Grid2>
            </form>
          </>
        }
      />
    </>
  );
};
