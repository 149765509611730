import { RouteItem } from "./models/RouteItem";

export const routes: RouteItem[] = [
  {
    path: "/overview",
    title: "Overview",
    image: "/img/overview.svg",
  },
  {
    path: "/overview/total-emissions",
    title: "Total-Emissions",
  },
  {
    path: "/overview/scope-1",
    title: "Scope-1-emissions",
  },
  {
    path: "/overview/scope-2",
    title: "Scope-2-emissions",
  },
  {
    path: "/overview/scope-3",
    title: "Scope-3-emissions",
  },
  {
    path: "/overview/other",
    title: "Others",
  },
  {
    path: "/emission",
    title: "Emission",
    image: "/img/input.svg",
  },
  {
    path: "/emission/add",
    title: "Add-Data",
  },
  {
    path: "/emission/import-data",
    title: "Import-Data",
  },
  {
    path: "/emission/eco-efficiency",
    title: "Eco-Efficiency",
  },
  {
    path: "/report",
    title: "Report",
    image: "/img/report.svg",
  },
  {
    path: "/report/tgo-cfo",
    title: "Tgo-Cfo",
  },
  {
    path: "/report/summary",
    title: "Summary",
  },
  {
    path: "/setting",
    title: "setting",
    image: "/img/framework-establishing.svg",
  },
  {
    path: "/setting/activities-template",
    title: "activity-templates",
  },
  {
    path: "/setting/access-control",
    title: "Access-Control",
  },
  {
    path: "/setting/accessility-and-permission",
    title: "Accessility-Permission",
  },
  {
    path: "/setting/economic-indicator",
    title: "Economic-Indicator",
  },
  {
    path: "/account",
    title: "Account",
    image: "/img/account.svg",
  },
  {
    path: "/setting/business-structure",
    title: "Business-Structure",
  },
];
