import { Box } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { CustomGridToolbarQuickFilter } from "../../../components/datagrid/CustomGridToolbarQuickFilter";
import { AddEconomicIndicator } from "./AddEconomicIndicator";
import { AddEconomicIndicator2 } from "./AddEconomicIndicator2";
import { useTranslation } from "react-i18next";

export const QuickSearchToolbar = () => {
  const { t } = useTranslation(["common", "economic"], {
    nsMode: "fallback",
  });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"40px"}
      marginBottom={"40px"}
    >
      <Box
        display={"flex"}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={"self-end"}
        gap={4}
      >
        <CustomGridToolbarQuickFilter />

        <GridToolbarContainer
          sx={{
            padding: 0,
            width: { xs: "100%", sm: "auto" },
          }}
        >
         <AddEconomicIndicator2 />
        </GridToolbarContainer>
      </Box>
    </Box>
  );
};
